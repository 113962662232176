<template>
  <div class="apply_content">
    <page-content padding="0px">
      <el-tabs
        v-model="activeName"
        type="border-card"
        @tab-click="changeState"
      >
        <el-tab-pane
          v-for="item in stateList"
          :key="item.value"
          :label="item.label"
          :name="item.value"
        />

        <el-table
          v-loading="loading"
          :data="tableData"
          border
          :header-cell-style="{
            background: '#D9DDE1',
            color: '#0D0202',
            'font-weight': 400
          }"
          style="width: 100%"
          element-loading-text="拼命加载中"
        >
          <el-table-column
            label="商品ID"
            prop="goodsId"
          />
          <el-table-column
            prop="name"
            label="商品图片"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 60px; min-height: 60px; color: #fff; display: flex; align-items: center;"
                :src="scope.row.imageUrls.split(',')[0]"
                :preview-src-list="scope.row.imageUrls.split(',')"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="goodsName"
          />
          <!-- <el-table-column
            label="商品信息"
            min-width="210"
          >
            <template slot-scope="scope">
              <div class="goods_message">
                <div class="left">
                  <img :src="scope.row.imageUrls" />
                </div>
                <div class="right">
                  <div class="title">
                    {{ scope.row.goodsName }}
                  </div>
                  <div class="id">
                    ID: {{ scope.row.goodsId }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="原价(元)"
            min-width="110"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.lowSellPrice || scope.row.highSellPrice">
                {{
                  scope.row.lowSellPrice == scope.row.highSellPrice ? `${scope.row.highSellPrice}` : `${scope.row.lowSellPrice} - ${scope.row.highSellPrice}`
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stockQuantity"
            label="商品库存"
            min-width="110"
          />
          <el-table-column
            v-if="activeName !== '-1'"
            label="活动价(元)"
            min-width="110"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.ancestryCategoryId === 2">/</div>
              <div v-else>
                <div v-if="scope.row.lowPromPrice || scope.row.highPromPrice">
                  {{
                    scope.row.lowPromPrice == scope.row.highPromPrice ? `${scope.row.lowPromPrice}` : `${scope.row.lowPromPrice} - ${scope.row.highPromPrice}`
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeName !== '-1'"
            prop="promStock"
            label="活动库存"
            min-width="110"
          >
            <template slot-scope="{row}">
              <div v-if="row.ancestryCategoryId === 2">/</div>
              <div v-else>{{row.promStock}}</div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="操作"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                v-if="activeName === '-1'"
                type="danger"
                plain
                size="mini"
                @click="onJoinOrEdit(scope.row, 'add')"
              >
                参加活动
              </el-button>
              <!-- 产品慕容，12月27日，要求在报名成功条件下隐藏取消报名按钮 -->
              <el-button
                v-if="activeName === '0'"
                type="danger"
                plain
                size="mini"
                style="marginRight: 10px"
                @click="onCancel(scope.row.id)"
              >
                取消报名
              </el-button>
              <el-button
                v-if="activeName === '0' && !isCsfr"
                type="danger"
                plain
                size="mini"
                @click="onJoinOrEdit(scope.row, 'edit')"
              >
                修改报名信息
              </el-button>
              <el-button
                v-if="activeName === '2' || activeName === '3'"
                type="danger"
                plain
                size="mini"
                @click="onJoinOrEdit(scope.row, 'readd')"
              >
                重新报名
              </el-button>
              <el-button
                v-if="scope.row.ancestryCategoryId != 2 && activeName === '1' && !isCsfr"
                type="danger"
                plain
                size="mini"
                @click="onJoinOrEdit(scope.row, 'increase')"
              >
                增加库存
              </el-button>
              <el-button
                style="margin-top: 4px"
                v-if="scope.row.ancestryCategoryId === 2"
                plain
                size="mini"
                @click="onJoinOrEdit(scope.row, 'showHome')"
              >
                查看规格
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :current-page="pagination.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.page_count"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.item_total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-tabs>
    </page-content>
    <change-goods-dialog
      v-if="joinDialogVisible"
      :join-dialog-visible.sync="joinDialogVisible"
      :operate-type="operateType"
      :operate-row-data="operateRowData"
      :prom-id="promId"
      @onupdatetable="updateTableData"
    />
    <!-- 名宿活动报名 -->
    <activeHome 
      :show.sync="showActiveHome" 
      :row="operateRowData" 
      :promId="$route.query.id"
      :auditStatus="0"
      @getList="getList" 
      :disabled="!showActiveBtn"
    />
    
  </div>
</template>

<script>
/**
 * 活动商品列表页面
 * 用途：用于供应商参加活动报名
 */
import { stateList } from '../config'
import changeGoodsDialog from './components/changeGoods.vue'
import activeHome from './components/activeHome.vue'

export default {
  name: 'ApplyList',
  components: {
    changeGoodsDialog,
    activeHome
  },
  data() {
    return {
      activeName: '-1',
      tableData: [],
      loading: false,
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      stateList,
      dialogVisible: false,
      addNum: 0,
      inventory: '',
      operateType: '',
      joinDialogVisible: false,
      operateRowData: {}, // 操作的每一行数据
      
      showActiveHome: false, //展示名宿活动报名
      showActiveBtn: true,//展示报名按钮
    }
  },
  computed: {
    isCsfr() {
      return this.$route.query.activityType === '6'
    }
  },
  mounted() {
    this.getList(this.activeName)
    this.promId = this.$route.query.id
  },
  methods: {
    // 状态改变
    changeState() {
      this.pagination.page = 1
      this.getList()
    },
    onJoinOrEdit(row, type) {
      if(type === 'showHome') {//查看名宿
        this.operateRowData = row;
        this.showActiveHome = true;
        this.showActiveBtn = false;
        return
      }
      if(row.ancestryCategoryId == 2) {
        this.operateRowData = row;
        this.showActiveHome = true;
        this.showActiveBtn = true;
      }else {
        this.operateType = type
        this.joinDialogVisible = true
        this.operateRowData = row
      }
    },
    updateTableData() {
      this.pagination.page = 1
      this.getList()
    },

    // 取消报名
    onCancel(id) {
      this.$confirm('是否确认取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: this.$api.tradeProm.cancel_apply,
          params: {
            promGoodsId: id
          }
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('取消报名成功~')
            this.getList()
          }
        }).catch(error => {
          console.log(error)
        })
      })
    },

    getList() {
      // index为页面当前页数
      let _this = this
      _this.loading = true
      _this
        .$axios({
          method: 'get',
          url: _this.$api.tradeProm.activity_goods_list,
          params: {
            currPage: _this.pagination.page,
            pageSize: _this.pagination.page_count,
            auditStatus: _this.activeName,
            storeId: _this.$route.query.storeId,
            promId: _this.$route.query.id
          }
        })
        .then((res) => {
          if (res.code === 0) {
            const { size, total, current, pages } = res.data
            _this.pagination = {
              page: Number(current),
              page_count: Number(size),
              page_total: Number(pages),
              item_total: Number(total)
            }
            _this.tableData = res.data.records
          } else {
            _this.tableData = []
            _this.pagination.item_total = 0
          }
          _this.loading = false
        })
    },

    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList(this.activeName)
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList(this.activeName)
    }
  }
}
</script>
<style lang="scss">
.goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
