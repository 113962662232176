var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apply_content" },
    [
      _c(
        "page-content",
        { attrs: { padding: "0px" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.changeState },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _vm._l(_vm.stateList, function (item) {
                return _c("el-tab-pane", {
                  key: item.value,
                  attrs: { label: item.label, name: item.value },
                })
              }),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    "header-cell-style": {
                      background: "#D9DDE1",
                      color: "#0D0202",
                      "font-weight": 400,
                    },
                    "element-loading-text": "拼命加载中",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "商品ID", prop: "goodsId" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "商品图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-image", {
                              staticStyle: {
                                width: "60px",
                                "min-height": "60px",
                                color: "#fff",
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: {
                                src: scope.row.imageUrls.split(",")[0],
                                "preview-src-list":
                                  scope.row.imageUrls.split(","),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品名称", prop: "goodsName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.activeName === "-1"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJoinOrEdit(
                                          scope.row,
                                          "add"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 参加活动 ")]
                                )
                              : _vm._e(),
                            _vm.activeName === "0"
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { marginRight: "10px" },
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCancel(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" 取消报名 ")]
                                )
                              : _vm._e(),
                            _vm.activeName === "0" && !_vm.isCsfr
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJoinOrEdit(
                                          scope.row,
                                          "edit"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 修改报名信息 ")]
                                )
                              : _vm._e(),
                            _vm.activeName === "2" || _vm.activeName === "3"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJoinOrEdit(
                                          scope.row,
                                          "readd"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 重新报名 ")]
                                )
                              : _vm._e(),
                            scope.row.ancestryCategoryId != 2 &&
                            _vm.activeName === "1" &&
                            !_vm.isCsfr
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJoinOrEdit(
                                          scope.row,
                                          "increase"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 增加库存 ")]
                                )
                              : _vm._e(),
                            scope.row.ancestryCategoryId === 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "4px" },
                                    attrs: { plain: "", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJoinOrEdit(
                                          scope.row,
                                          "showHome"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 查看规格 ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.pagination.page_count,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pagination.item_total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.joinDialogVisible
        ? _c("change-goods-dialog", {
            attrs: {
              "join-dialog-visible": _vm.joinDialogVisible,
              "operate-type": _vm.operateType,
              "operate-row-data": _vm.operateRowData,
              "prom-id": _vm.promId,
            },
            on: {
              "update:joinDialogVisible": function ($event) {
                _vm.joinDialogVisible = $event
              },
              "update:join-dialog-visible": function ($event) {
                _vm.joinDialogVisible = $event
              },
              onupdatetable: _vm.updateTableData,
            },
          })
        : _vm._e(),
      _c("activeHome", {
        attrs: {
          show: _vm.showActiveHome,
          row: _vm.operateRowData,
          promId: _vm.$route.query.id,
          auditStatus: 0,
          disabled: !_vm.showActiveBtn,
        },
        on: {
          "update:show": function ($event) {
            _vm.showActiveHome = $event
          },
          getList: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }